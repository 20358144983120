import React from 'react'
import * as styles from './form.module.css'

const Forgot = (({ username, handleUpdate, handleSubmit, email, isDisabled, errors, history }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
  >
    <p className={styles[`form__instructions`]} />
    <label className={styles[`form__label`]}>
      Email
      <input
        className={styles[`form__input`]}
        type="email"
        name="username"
        value={username}
        onChange={handleUpdate}
      />
    </label>
    <input
      className={styles[`form__button`]}
      type="submit"
      value="Request Password Reset"
      disabled={isDisabled}
    />
  </form>
))

export default Forgot
